<template>
<dash-page-new
    :title="$t('mailer.single.title')"
    :root="$t('Newsletter')"
    :no-content-condition="!loading && items.length === 0"
    :loading="loading"
>

  <template #header_action>
    <ws-button
        @click="openNewCampaign"
        label="Create"
        left-icon="mdi-plus"
    />
  </template>

  <template #default>
    <ws-data-table
        class="mt-5"
        :items="items"
        :headers="headers"
        :row-action="openCampaign"
    >

      <template #item.name="{item}">
        <h5 class="wsDARKER shorten-text" style="min-width: 200px; font-size: 12px">{{ item.name }}</h5>
        <h5 class="wsDARKLIGHT mt-1" style="font-size: 12px"> {{ MONTH_DAY_TIME(item.date_modified,true,true) }}</h5>
      </template>

      <template #item.status="{item}">
        <ws-chip :color="NEWSLETTER_STATUS_COLOR(item.status)" no-wrap :text="getStatusText(item.status)" outlined />
      </template>

      <template #item.sent="{item}">
        <h5>{{ calculateStatsPercent(item, 'sent') }} </h5>
      </template>

      <template #item.opened="{item}">
        <h5>{{ calculateStatsPercent(item, 'opened')  }}</h5>
      </template>

      <template #item.clicks="{item}">
        <h5>{{ calculateStatsPercent(item, 'clicks') }}</h5>
      </template>

      <template #item.unsubscribed="{item}">
        <h5>{{ calculateStatsPercent(item, 'unsubscribed') }}</h5>
      </template>

      <template #item.failed="{item}">
        <h5>{{ calculateStatsPercent(item, 'failed')}} </h5>
      </template>

      <template #item.action="{item}">

        <ft-select
            @input="editAction($event,item)"
            :items="actionsSelect"
        >
          <v-btn icon>
            <v-icon :color="wsACCENT">mdi-dots-horizontal</v-icon>
          </v-btn>
        </ft-select>

      </template>


    </ws-data-table>
  </template>

  <template #no-content>
    <div class="fill-height d-flex  justify-center">

      <div style="margin-top : 150px">
        <div class="d-flex justify-center">
          <ws-img height="250" contain src="/library/img/finemailer/newsletter_logo.png" />
        </div>

        <h2 class="wsDARKER text-center">{{ $t('mailer.single.no_content.title') }}</h2>
        <h5 class="font-weight-regular wsACCENT mt-2 text-center">{{ $t('mailer.single.no_content.title') }}</h5>

        <div class="d-flex justify-center">
          <ws-button
              @click="openHelpVideo"
              left-icon="mdi-video"
              label="mailer.single.help_button"
              class="mt-6"
              outlined
              :color="wsACCENT"
          />
        </div>
      </div>


    </div>
  </template>

  <template #dialog>
    <newsletter-wizard
        v-if="displayDialog"
        v-model="displayDialog"
        :newsletter="selectedNewsletter"
        @create="addNewsletter"
        single
    />
  </template>

</dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import NewsletterWizard from "@/modules/finemailer/components/newsletterWizard/NewsletterWizard.vue";
import finemailer from "@modules/finemailer/mixins/finemailer";

export default {
  name: "mailerLetters",
  mixins : [finemailer],
  components : {
    NewsletterWizard
  },
  data() {
    return {
      displayDialog : false,
      selectedItem : {},
      selectedNewsletter : {},
      loading : true,

      items : [
        // {
        //   uuid : '324322323',
        //   name : "Запрошення на участь в вебінарі по проектуванню архітектури кода",
        //   status : "sent",
        //   receivers : 129,
        //   sent : 112,
        //   opened : 60,
        //   link_clicks : 60,
        //   unsubscribed : 3,
        //   bounced : 5,
        //   date_created : 1705332171,
        // }
      ]
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') ,   value : 'name'       , sortable : false},
        { text : this.$t('Status') , value : 'status'     , align : 'center'       , sortable : false},
        { text : this.$t('mailer.campaigns.receivers')    , value : 'receivers'    , align : 'center', sortable : false},
        { text : this.$t('mailer.campaigns.delivered')    , value : 'sent'         , align : 'center', sortable : false},
        { text : this.$t('mailer.campaigns.opened')       , value : 'opened'       , align : 'center', sortable : false},
        { text : this.$t('mailer.campaigns.link_clicks')  , value : 'clicks'       ,  align : 'center', sortable : false},
        { text : this.$t('mailer.campaigns.unsubscribed') , value : 'unsubscribed' , align : 'center', sortable : false},
        { text : this.$t('mailer.campaigns.bounced')      , value : 'failed'       , align : 'center', sortable : false},
        { value : 'action' , sortable : false }
      ]
    },
    actionsSelect() {
      return [
        { text : this.$t('Open') , value : 'view' },
        { text : this.$t('Edit') , value : 'edit'},
        { text : this.$t('Delete') , value : 'delete'}
      ]
    }
  },
  methods : {
    ...mapActions('finemailer' , [
        'GET_NEWSLETTERS'
    ]),
    openHelpVideo() {
      this.notify('Open help video')
    },
    addNewsletter(item) {
      this.items.unshift(item)
    },

    openCampaign(item) {
      if ( item.status !== 'draft') {
        this.$router.push(this.businessDashLink(`fine_mailer/view/${item.uuid}`))
        return
      }
      this.selectedNewsletter = this.COPY(item)
      this.displayDialog = true
    },
    openNewCampaign() {
      this.selectedNewsletter = {}
      this.displayDialog = true
    },

    editAction(action , item) {
      this.notify(item)
    },

    // technical
    calculateStatsPercent(item, param) {
      if ( !item.receivers) {
        return '0% / 0'
      }
      return parseInt(item[param]/item.receivers*100) + '% / ' + item[param]
    },
    getStatusText(status) {
      if ( !status ) {
        return ''
      }
     return this.$t(`mailer.campaigns.status.${status}`)
    },

    async initPage() {
      this.loading = true
      let result = await this.GET_NEWSLETTERS()

      if ( !result ) {
        this.loading = false
        return this.ERROR()
      }

      this.items = result.items
      this.loading = false

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>